export default (blob: Blob) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.onload = (e) => {
			resolve(e.target?.result)
		}
		fileReader.readAsDataURL(blob)
		fileReader.onerror = () => {
			reject(new Error('blobToBase64 error'))
		}
	})
}

/** base64转文件 */
export const base64ToFile = (base64: string, fileName = '未命名曲谱') => {
	const arr = base64.split(',')
	if (!arr) {
		return undefined
	}
	const mime = arr[0] && (arr[0] as any).match(/:(.*?);/)[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	const blob = new Blob([u8arr], { type: mime })
	return new File([blob], fileName + '.' + mime.split('/')[1], {
		type: mime
	})
}
/** 字符串转文件 */
export const stringToFile = (
	xml: string,
	fileName = '未命名曲谱',
	suffix = '.evxml'
) => {
	if (!xml) {
		return undefined
	}
	const blob = new Blob([xml])
	return new File([blob], fileName + suffix)
}

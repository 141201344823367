import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d8fe26b2")
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailElement = _resolveComponent("ThumbnailElement")!

  return (_openBlock(), _createElementBlock("div", {
    id: `thumbnail-slide-${_ctx.slide.id}`,
    ref: "thumbnailSlideRef",
    style: _normalizeStyle({
        width: _ctx.size + 'px',
        height: _ctx.size * _ctx.viewportRatio + 'px',
        backgroundImage: _ctx.slidePreviewUrl
            ? `url('${_ctx.compressSlidePreviewUrl}')`
            : 'none'
    }),
    class: "thumbnail-slide"
  }, [
    (((!_ctx.slide.preview && _ctx.screening) ||
        (!_ctx.slidePreviewUrl && !_ctx.screening)) &&
        !_ctx.isImportingPptx
        )
      ? (_openBlock(), _createElementBlock("div", {
          id: `thumbnailSlide-${_ctx.slide.id}`,
          key: `${_ctx.slide.id}-render`,
          style: _normalizeStyle({
        width: _ctx.VIEWPORT_SIZE + 'px',
        height: _ctx.VIEWPORT_SIZE * _ctx.viewportRatio + 'px'
        // transform: `scale(${scale})`
    }),
          class: _normalizeClass([_ctx.screening ? 'elements-screen' : 'elements'])
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.backgroundStyle),
            class: "background"
          }, null, 4),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide.elements, (element, index) => {
            return (_openBlock(), _createBlock(_component_ThumbnailElement, {
              key: element.id,
              elementIndex: index + 1,
              elementInfo: element,
              "preview-sign": true
            }, null, 8, ["elementIndex", "elementInfo"]))
          }), 128))
        ], 14, _hoisted_2))
      : _createCommentVNode("", true)
  ], 12, _hoisted_1))
}